import { SettingsValues, loadSettingsValues } from '$/app/utils';
import { hideSplashScreen } from '$/lib/splash-screen';
import { Logger } from '$shared/logger';
import { alcomyJWTSchema } from '$shared/security/jwt';
import { LoginData } from '$shared/services/user';
import { TypeBox } from '$shared/type-box';
import { inject } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Store } from '@ngrx/store';
import { jwtDecode } from 'jwt-decode';
import { AuthenticationActions } from '../authentication/authentication.actions';
import { AppUpdateService } from '../services';
import { FeathersService } from '../services/feathers.service';
import { GeolocationService } from '../services/utils/geolocation.service';

const NO_AUTH_PATHS = ['/signup', '/forgot-password', '/reset-password'];

export function initializeApp() {
  // TODO(2024-03-18): Ask the server if the app version is acceptable
  // QUESTION: If the version is not valid, can we blow away the service worker's cache?
  // QUESTION: In the native app, can we force the user to update?

  const store = inject(Store);
  const feathers = inject(FeathersService);
  const appUpdate = inject(AppUpdateService);
  const geolocationService = inject(GeolocationService);

  return async () => {
    await loadSettingsValues();
    geolocationService.initialize();
    await SplashScreen.hide();

    Logger.info('Initializing app', { location: window.location });

    if (isNoAuthRoute()) {
      SettingsValues.ACCESS_TOKEN = null;
    }

    await appUpdate.init();

    // NOTE: It is important that initializeApp waits for this function to complete.
    // If it does not, route navigation can occur before the user is authenticated,
    // which can lead to unexpected behavior, and general app instability at startup.
    // In particular, this can cause the "white screen" problem.
    const success = await authenticateWithStoredToken();

    if (!success) {
      hideSplashScreen();
    }
  };

  function isNoAuthRoute(): boolean {
    return NO_AUTH_PATHS.includes(window.location.pathname);
  }

  async function authenticateWithStoredToken(): Promise<boolean> {
    if (!validateAccessToken()) {
      return false;
    }

    try {
      store.dispatch(AuthenticationActions.authenticateWithStoredToken());

      const loginData = (await feathers.client.reAuthenticate()) as LoginData;

      return new Promise((resolve) => {
        store.dispatch(
          AuthenticationActions.authenticateWithStoredTokenSuccess({
            loginData,
            params: {
              onComplete: () => resolve(true)
            }
          })
        );
      });
    } catch (error) {
      store.dispatch(
        AuthenticationActions.authenticateWithStoredTokenFail({ error })
      );

      return false;
    }
  }

  function validateAccessToken(): boolean {
    const token = SettingsValues.ACCESS_TOKEN;

    if (!token) {
      return false;
    }

    const decodedToken = TypeBox.validate(alcomyJWTSchema, jwtDecode(token));

    if (!decodedToken) {
      SettingsValues.ACCESS_TOKEN = null;

      return false;
    }

    const isExpired = Number(decodedToken.exp * 1000) < Date.now();

    if (isExpired) {
      SettingsValues.ACCESS_TOKEN = null;

      return false;
    }

    return true;
  }
}
