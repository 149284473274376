import { TypeBox } from '../type-box';

export const alcomyJWTSchema = TypeBox.LooseObject({
  orgId: TypeBox.Id(),
  facilityId: TypeBox.Id(),
  facilityUserId: TypeBox.Id(),
  iat: TypeBox.Number(),
  exp: TypeBox.Number(),
  aud: TypeBox.String(),
  iss: TypeBox.String(),
  sub: TypeBox.Id(),
  jti: TypeBox.String()
});

export type AlcomyJWT = TypeBox.Infer<typeof alcomyJWTSchema>;
