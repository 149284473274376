import { OverlayService } from '$/app/services';
import { AuthManagementApiService } from '$/app/services/api/auth-management.service';
import { SharedModule } from '$/app/shared/shared.module';
import { AlcomyValidators } from '$/app/shared/validators';
import { SettingsValues, validateForm } from '$/app/utils';
import { Logger } from '$shared/logger';
import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { filter, toLower, trim } from 'lodash';

const PAGE_STATES = ['READY', 'EMAIL_SENT'] as const;

type PageState = (typeof PAGE_STATES)[number];

@Component({
  standalone: true,
  imports: [SharedModule],
  selector: 'alc-forgot-password-page',
  templateUrl: 'forgot-password.page.html'
})
export class ForgotPasswordPage {
  private readonly fb = inject(UntypedFormBuilder);
  private readonly overlay = inject(OverlayService);
  private readonly authManagement = inject(AuthManagementApiService);

  protected pageState: PageState = 'READY';

  protected readonly form = this.fb.group({
    email: ['', [Validators.required, AlcomyValidators.email]]
  });

  private readonly emailChanges = toSignal(
    this.form.controls.email.valueChanges
  );

  protected readonly processing = signal(false);

  protected readonly emailSuggestions = computed(() => {
    const typedEmail = this.emailChanges() ?? '';

    return filter(SettingsValues.EMAILS_ON_THIS_DEVICE, (email) =>
      email.includes(typedEmail)
    );
  });

  protected async submit() {
    if (this.processing()) {
      return;
    }

    try {
      this.processing.set(true);

      let email: string;

      try {
        ({ email } = validateForm(this.form));
      } catch {
        this.overlay.showToast('failure', 'Please fix the invalid fields');

        return;
      }

      try {
        await this.authManagement.sendResetPwd({ email: toLower(trim(email)) });

        this.pageState = 'EMAIL_SENT';
      } catch (error) {
        const toastMessage = this.processError(error);

        if (toastMessage) {
          this.overlay.showToast('failure', toastMessage);
        }
      }
    } finally {
      this.processing.set(false);
    }
  }

  private processError(error: any): string | undefined {
    if (error.message === 'User not found.') {
      this.form.controls.email.setErrors({ emailNotFound: true });

      return 'Email not found';
    }

    Logger.error('Error sending reset password email', { error });

    return undefined;
  }
}
