import { OverlayService } from '$/app/services';
import { AuthManagementApiService } from '$/app/services/api/auth-management.service';
import { AlcPasswordInputComponent } from '$/app/shared/components/password-input/password-input.component';
import { SharedModule } from '$/app/shared/shared.module';
import { validateForm } from '$/app/utils';
import { ValidationError } from '$/models';
import { Logger } from '$shared/logger';
import { Component, effect, inject, input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'alc-reset-password-page',
  templateUrl: 'reset-password.page.html',
  imports: [SharedModule, AlcPasswordInputComponent]
})
export class ResetPasswordPage {
  private readonly overlay = inject(OverlayService);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly authManagement = inject(AuthManagementApiService);

  protected readonly form = this.fb.group({
    newPassword: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  });

  protected uiState:
    | 'loading'
    | 'tokenValidated'
    | 'tokenExpired'
    | 'tokenUsed'
    | 'resetSuccessful'
    | 'error' = 'loading';

  public readonly token = input<string>();

  #_effects = [
    effect(async () => {
      await this.validateToken();
    })
  ];

  protected async resetPassword() {
    try {
      const formValue = validateForm(this.form);

      try {
        await this.authManagement.resetPwdLong({
          token: this.token(),
          newPassword: formValue.newPassword
        });

        this.uiState = 'resetSuccessful';
      } catch (error) {
        Logger.error('Error while resetting password', { error });
      }
    } catch (error) {
      Logger.error('Error while validating reset password page', {
        error
      });

      if (error instanceof ValidationError) {
        this.overlay.showAlert(error.message, 'Form fields invalid');
      }

      this.uiState = 'error';
    }
  }

  private async validateToken() {
    const token = this.token();

    if (!token) {
      this.uiState = 'error';
      return;
    }

    try {
      await this.authManagement.validateResetToken({ resetToken: token });
      this.uiState = 'tokenValidated';
    } catch (error) {
      switch (error.className) {
        case 'expired-token-error':
          this.uiState = 'tokenExpired';
          break;

        case 'not-found':
          this.uiState = 'tokenUsed';
          break;

        default:
          Logger.error('Unexpected error while validating token', { error });
          this.uiState = 'error';
          break;
      }
    }
  }
}
