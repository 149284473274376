<ion-content>
  <div class="flex h-full items-center justify-center text-medium-dark">
    <div
      class="mat-elevation-z4 flex max-w-sm flex-col rounded-3xl bg-white p-12 pt-6"
    >
      @switch (uiState) {
        @case ('loading') {
          <div class="flex size-[300px] items-center justify-center">
            <ion-spinner />
          </div>
        }

        @case ('tokenValidated') {
          <h1 class="text-center">Reset Your Password</h1>

          <form class="pt-6" [formGroup]="form" novalidate>
            <alc-password-input
              id="password"
              controlName="newPassword"
              label="New Password"
              class="form-input-stretch"
            />

            <alc-password-input
              controlName="confirmPassword"
              label="Confirm Password"
              class="form-input-stretch mt-8"
              mustMatchField="newPassword"
              [validateCharacters]="false"
            />
          </form>

          <ion-button
            class="pt-6"
            type="submit"
            [disabled]="form.status === 'INVALID'"
            (click)="resetPassword()"
            shape="round"
          >
            Reset Password
          </ion-button>
        }

        @case ('resetSuccessful') {
          <h1 class="text-center">Password Reset Successful!</h1>

          <p>
            <ion-text>
              You are all set. You can now use your new password to login.
            </ion-text>
          </p>

          <ion-button [routerLink]="['/login']" shape="round">
            Go to Login
          </ion-button>
        }

        @case ('tokenExpired') {
          <h1 class="text-center">Reset Link Expired</h1>

          <p>
            <ion-text>
              This link to reset your password has expired. Please click the
              <b>Get A New Link</b> button below to have a new link sent to you.
            </ion-text>
          </p>

          <ion-button
            class="py-8"
            [routerLink]="['/forgot-password']"
            shape="round"
          >
            Get A New Link
          </ion-button>

          <ion-button [routerLink]="['/login']" fill="outline" shape="round">
            Go to Login Page
          </ion-button>
        }

        @case ('tokenUsed') {
          <h1 class="text-center">Link No Longer Valid</h1>

          <p>
            <ion-text>
              A link may become invalid if it has already been used, or if a new
              link has been sent to you. This is common if you have clicked a
              link from an older reset password email.
            </ion-text>
          </p>

          <p>
            <ion-text>
              Please check your email for the latest reset password email. You
              can also request a new link by clicking the
              <b>Get A New Link</b> button below.
            </ion-text>
          </p>

          <ion-button
            class="py-8"
            [routerLink]="['/forgot-password']"
            shape="round"
          >
            Get A New Link
          </ion-button>

          <ion-button [routerLink]="['/login']" fill="outline" shape="round">
            Go to Login Page
          </ion-button>
        }

        @case ('error') {
          <h1 class="text-center">An error occurred</h1>

          <p>
            <ion-text>
              Oops! Something went wrong. Please try again later.
            </ion-text>
          </p>

          <ion-button class="pt-8" [routerLink]="['/login']" shape="round">
            Go to Login Page
          </ion-button>
        }
      }
    </div>
  </div>
</ion-content>
