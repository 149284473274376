export abstract class Result<T, E extends Error = Error> {
  abstract isOk(): this is Ok<T>;
  abstract isErr(): this is Err<E>;
  abstract unwrap(): T;
  abstract expect(msg: string): T;

  static OK<T>(ok: T): Result<T, never> {
    return new Ok(ok);
  }

  static Err<E extends Error>(err: E): Result<never, E> {
    return new Err(err);
  }
}

export class UnwrapError extends Error {
  originalError: Error;

  constructor(message: string, originalError: Error) {
    super(message);
    this.originalError = originalError;
  }
}

export class ExpectError extends Error {
  originalError: Error;

  constructor(message: string, originalError: Error) {
    super(message);
    this.originalError = originalError;
  }
}

class Ok<T> implements Result<T, never> {
  private ok: T;

  constructor(ok: T) {
    this.ok = ok;
  }

  isOk(): this is Ok<T> {
    return true;
  }

  isErr(): this is Err<never> {
    return false;
  }

  unwrap(): T {
    return this.ok;
  }

  expect(): T {
    return this.ok;
  }
}

class Err<E extends Error> implements Result<never, E> {
  public err: E;

  constructor(err: E) {
    this.err = err;
  }

  isOk(): this is Ok<never> {
    return false;
  }

  isErr(): this is Err<E> {
    return true;
  }

  unwrap(): never {
    throw new UnwrapError('Unwrap called on Err', this.err);
  }

  expect(msg: string): never {
    throw new ExpectError(msg, this.err);
  }
}
