import shimArrayAt from 'array.prototype.at/shim';
import 'disposablestack/auto';

shimArrayAt();

import { AuthenticationService } from '$/app/authentication/authentication.service';
import { initializeAppInfo } from '$/app/services';
import {
  ShowOnDirtyTouchedErrorStateMatcher,
  errorHandlerFactory,
  initLogger
} from '$/app/utils';
import { environment } from '$/environments/environment';
import { Logger } from '$shared/logger';
import { backgroundTask } from '$shared/utils';
import { version } from '$shared/version';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { provideHttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  enableProdMode,
  importProvidersFrom
} from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  HAMMER_GESTURE_CONFIG,
  HammerModule,
  bootstrapApplication
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  RouteReuseStrategy,
  Router,
  provideRouter,
  withComponentInputBinding,
  withPreloading
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { IonicRouteStrategy } from '@ionic/angular/common';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import * as Sentry from '@sentry/angular-ivy';
import 'hammerjs';
import { addIcons } from 'ionicons';
import {
  add,
  addOutline,
  cameraOutline,
  caretDownOutline,
  checkmarkCircleOutline,
  close,
  closeOutline,
  imagesOutline,
  timeOutline,
  warningOutline
} from 'ionicons/icons';
import { Settings } from 'luxon';
import { provideNgxMask } from 'ngx-mask';
import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.routes';
import { initializeApp } from './app/initialization/initialize-app';
import { AppStoreModule } from './app/store';
import { CustomHammerGestureConfig } from './config/hammer-gesture-config';
import { updateSplashScreenText } from './lib/splash-screen';

initLogger();

window.addEventListener('unhandledrejection', (event) => {
  Logger.error('Unhandled Promise Rejection', event.reason);
});

window.addEventListener('error', (event) => {
  event.preventDefault();

  Logger.error('Unhandled Error', event.error);
});

let sampleRate = 0.1;

if (environment.production) {
  enableProdMode();
  sampleRate = 0.01;
}

if (environment.name !== 'develop') {
  Sentry.init({
    environment: environment.name,
    release: `alcomy@${version}`,
    dsn: 'https://dbea97301a0579b66bc51180653833cc@o4506006920364032.ingest.sentry.io/4506006950051840',
    integrations: [
      Sentry.browserTracingIntegration({
        // the default behavior triggers CORS errors and doesn't work with websockets
        tracePropagationTargets: []
      }),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: sampleRate,
    // Session Replay
    replaysSessionSampleRate: sampleRate,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: ['NotAuthenticated', 'Forbidden']
  });
}

Settings.throwOnInvalid = true;
declare module 'luxon' {
  interface TSSettings {
    throwOnInvalid: true;
  }
}

backgroundTask('app startup', async () => {
  updateSplashScreenText('Initializing app...');

  addIcons({
    add,
    addOutline,
    cameraOutline,
    caretDownOutline,
    checkmarkCircleOutline,
    close,
    closeOutline,
    imagesOutline,
    timeOutline,
    warningOutline
  });

  await Promise.all([initializeAppInfo(), defineCustomElements(window)]);

  await bootstrapApplication(AppComponent, {
    providers: [
      provideRouter(
        APP_ROUTES,
        withComponentInputBinding(),
        withPreloading(PreloadAllModules)
      ),
      provideHttpClient(),
      provideAnimations(),
      AuthenticationService,
      CallNumber,
      provideNgxMask(),
      { provide: ErrorHandler, useFactory: errorHandlerFactory },
      {
        provide: ErrorStateMatcher,
        useClass: ShowOnDirtyTouchedErrorStateMatcher
      },
      {
        provide: Sentry.TraceService,
        deps: [Router]
      },
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      {
        provide: APP_INITIALIZER,
        useFactory: initializeApp,
        deps: [Sentry.TraceService],
        multi: true
      },
      {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: { appearance: 'outline' }
      },
      provideIonicAngular({
        mode: 'md',
        innerHTMLTemplatesEnabled: true,
        spinner: 'circles'
      }),
      importProvidersFrom(
        AppStoreModule,
        HammerModule,
        ClipboardModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        // This module is imported in AlcCommon, but it doesn't seem like the
        // providers get imported Specifically, there is a DecimalProvider that
        // will cause problems if not imported. This module is only needed to
        // support the RxwebValidators portion of the library.
        RxReactiveFormsModule
      ),
      {
        provide: HAMMER_GESTURE_CONFIG,
        useClass: CustomHammerGestureConfig
      }
    ]
  });
});
