import { AuthenticationActions } from '$/app/authentication/authentication.actions';
import { AppInfo, OverlayService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { AlcPasswordInputComponent } from '$/app/shared/components/password-input/password-input.component';
import { AlcVersionMarkerComponent } from '$/app/shared/components/version-marker/version-marker.component';
import { SharedModule } from '$/app/shared/shared.module';
import { AlcomyValidators } from '$/app/shared/validators';
import { AppActions } from '$/app/store/app.actions';
import { SettingsValues, validateForm } from '$/app/utils';
import { environment } from '$/environments/environment';
import { computedWith } from '$/lib/signals/computed-with';
import { Logger } from '$shared/logger';
import { LoginData } from '$shared/services/user';
import { Result } from '$shared/types/result';
import { Component, inject, viewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { filter, toLower, trim, without } from 'lodash';

const TOAST_MESSAGES = {
  'email-not-found-error': 'Email not found',
  'invalid-password-error': 'Password is incorrect',
  'app-out-of-date-error': 'App is out of date'
};

@Component({
  standalone: true,
  imports: [SharedModule, AlcVersionMarkerComponent, AlcPasswordInputComponent],
  selector: 'alc-login-page',
  templateUrl: 'login.page.html'
})
export class LoginPage {
  private readonly fb = inject(UntypedFormBuilder);
  private readonly store = inject(Store);
  private readonly overlay = inject(OverlayService);
  private readonly feathers = inject(FeathersService);

  protected readonly emailAutocomplete = viewChild<MatAutocomplete>('auto');

  protected readonly form = this.fb.group({
    email: ['', [Validators.required, AlcomyValidators.email]],
    password: ['']
  });

  protected hidePassword = true;
  protected readonly environment = environment;
  protected readonly AppInfo = AppInfo;

  protected readonly emailSuggestions = computedWith(
    this.form.controls.email.valueChanges,
    (emailChanges) => {
      const partialEmail = emailChanges();

      return filter(SettingsValues.EMAILS_ON_THIS_DEVICE, (storedEmail) =>
        storedEmail.includes(partialEmail)
      );
    }
  );

  ionViewWillEnter() {
    this.store.dispatch(AppActions.clearStore());

    this.form.controls.email.reset();
    this.form.controls.password.reset();
    this.form.controls.email.setValue(SettingsValues.LAST_USED_EMAIL);

    this.hidePassword = true;
  }

  protected async submit() {
    this.emailAutocomplete().showPanel = false;

    using loading = this.overlay.loading('Logging you in...', {
      disableAutoHide: true
    });

    try {
      validateForm(this.form);
    } catch (error) {
      this.overlay.showToast('failure', 'Please fix the invalid fields');

      return;
    }

    const email = toLower(trim(this.form.value.email));
    const password = trim(this.form.value.password);

    loading.show();

    this.store.dispatch(AuthenticationActions.login());

    const result = await this.login(email, password);

    if (result.isErr()) {
      loading.hide();

      return this.store.dispatch(
        AuthenticationActions.loginFail({ error: result.err })
      );
    }

    this.store.dispatch(
      AuthenticationActions.loginSuccess({
        loginData: result.unwrap(),
        params: {
          onComplete: () => loading.hide()
        }
      })
    );
  }

  private async login(
    email: string,
    password: string
  ): Promise<Result<LoginData>> {
    try {
      const loginData = (await this.feathers.client.authenticate({
        description: 'email/password login',
        strategy: 'local',
        email,
        password
      })) as LoginData;

      return Result.OK(loginData);
    } catch (error) {
      const errorClass = error?.className;

      this.form.controls.password.setErrors(null);
      this.form.controls.email.setErrors(null);

      switch (error?.className) {
        case 'email-not-found-error':
          SettingsValues.EMAILS_ON_THIS_DEVICE = without(
            SettingsValues.EMAILS_ON_THIS_DEVICE,
            email
          );

          this.form.controls.email.setErrors({ emailNotFound: true });
          break;

        case 'invalid-password-error':
          this.form.controls.password.setErrors({
            invalidPassword: true
          });
          break;

        case 'app-out-of-date-error':
          // TODO(2024-03-18): This should really be handled at app init - we should not be able to get here
          break;

        default:
          Logger.error('Unexpected error during login', error);
          break;
      }

      await this.overlay.showToast(
        'failure',
        TOAST_MESSAGES[errorClass] ?? 'Login failed'
      );

      return Result.Err(error);
    }
  }
}
