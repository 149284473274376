<ion-content>
  <div class="flex h-full items-center justify-center">
    <ion-card
      class="flex size-full flex-col items-center overflow-y-auto rounded-none p-6 sm:h-fit sm:max-w-[450px] sm:rounded-2xl"
    >
      <div class="flex w-full flex-col items-center pt-10 sm:pt-4">
        <img
          class="h-[92px]"
          src="assets/images/alcomy_logo.png"
          alt="Alcomy Logo"
        />
        <p class="mt-2 text-2xl">Empowering those who care</p>
      </div>

      <form [formGroup]="form" class="h-full max-w-[300px] pt-10">
        <mat-form-field class="form-input-stretch">
          <mat-label class="text-xl">Email</mat-label>
          <input
            type="email"
            data-testid="email"
            class="!text-xl"
            matInput
            formControlName="email"
            (keydown.enter)="submit()"
            [matAutocomplete]="auto"
          />

          <mat-autocomplete #auto="matAutocomplete">
            @for (option of emailSuggestions(); track option) {
              <mat-option [value]="option" class="!text-xl">
                {{ option }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error [alcError]="form.controls.email" />
        </mat-form-field>

        <alc-password-input
          class="form-input-stretch pt-6 !text-xl"
          inputClass="!text-xl"
          [validateCharacters]="false"
          (keydownEnter)="submit()"
          [hidePassword]="hidePassword"
        />

        <div class="flex flex-col items-end pb-6 text-base">
          <a class="no-underline" routerLink="/forgot-password">
            Forgot password?
          </a>
        </div>

        <ion-button
          data-testid="login-button"
          type="submit"
          expand="block"
          shape="round"
          alcClickStopPropagation
          (click)="submit()"
        >
          Login
        </ion-button>
      </form>

      <ion-row class="flex flex-col justify-center pt-20 text-base">
        Don't have an account?

        <a class="no-underline" routerLink="/new-to-alcomy">
          Learn How to Get One
        </a>
      </ion-row>

      <alc-version-marker class="w-full items-center pt-4 text-medium-light" />
    </ion-card>
  </div>
</ion-content>
