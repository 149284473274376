<ion-content>
  <div class="flex h-full items-center justify-center">
    <ion-card
      class="flex size-full flex-col items-center rounded-none p-6 sm:h-fit sm:max-w-[450px] sm:rounded-2xl"
    >
      <form [formGroup]="form" class="h-full max-w-[300px] py-4">
        @switch (pageState) {
          @case ('READY') {
            <p class="text-center text-2xl">Forgot Your Password?</p>

            <p class="py-4 text-center text-base">
              Enter your email below and we'll send you a reset password link.
            </p>

            <mat-form-field class="form-input-stretch pb-4">
              <mat-label class="text-xl">Email</mat-label>

              <input
                type="email"
                data-testid="email-input"
                class="!text-xl"
                matInput
                formControlName="email"
                (keydown.enter)="submit()"
                [matAutocomplete]="auto"
              />

              <mat-autocomplete #auto="matAutocomplete">
                @for (option of emailSuggestions(); track option) {
                  <mat-option [value]="option" class="!text-xl">
                    {{ option }}
                  </mat-option>
                }
              </mat-autocomplete>

              <mat-error [alcError]="form.controls.email" />
            </mat-form-field>

            <ion-button
              data-testid="reset-password-button"
              expand="block"
              shape="round"
              [disabled]="processing()"
              (click)="submit()"
            >
              Reset My Password
            </ion-button>

            <p class="pt-10 text-center text-base">
              Actually, I do remember my password.
              <br />
              Go back to <a [routerLink]="['/login']">login</a>
            </p>
          }

          @case ('EMAIL_SENT') {
            <div
              class="flex size-full flex-col items-center justify-center gap-4"
            >
              <p class="m-0 text-center text-2xl" data-testid="success-message">
                Reset Password Email Sent!
              </p>

              <p class="text-base">
                An email with the reset password link has been sent to
                {{ this.form.controls.email.value }}
              </p>

              <ion-button [routerLink]="['/login']" shape="round">
                Go to Login
              </ion-button>
            </div>
          }
        }
      </form>
    </ion-card>
  </div>
</ion-content>
