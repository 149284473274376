@if (form) {
  <ion-content>
    <div class="flex h-full items-center justify-center">
      <ion-card
        class="size-full overflow-y-auto rounded-none p-6 text-base md:h-fit md:max-w-[450px] md:rounded-2xl"
      >
        <div class="flex w-full flex-col items-center pt-10 sm:pt-4">
          <img
            class="h-[92px]"
            src="assets/images/alcomy_logo.png"
            alt="Alcomy Logo"
          />
          <p class="mt-2 text-2xl">Empowering those who care</p>
        </div>

        @switch (status()) {
          @case ('Loading') {
            <div class="flex w-full flex-col items-center">
              <ion-spinner />
            </div>
          }

          @case ('InvitationSent') {
            <div class="flex w-full flex-col items-center">
              <p class="mt-6 text-center">
                Your invitation has been sent! Please check your email for
                further instructions.
              </p>
            </div>
          }

          @case ('Expired') {
            <div class="flex w-full flex-col items-center">
              <p class="mt-6 text-center">Your invitation has expired!</p>

              <ion-button
                class="form-buttons"
                expand="block"
                shape="round"
                data-testid="submit-button"
                (click)="requestNewInvitation()"
              >
                Send me a new invitation
              </ion-button>
            </div>
          }

          @case ('Valid') {
            <form [formGroup]="form" class="flex flex-col gap-4">
              <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                <mat-form-field class="form-input-stretch">
                  <mat-label class="!text-xl">First Name</mat-label>
                  <input
                    class="!text-xl"
                    matInput
                    formControlName="firstName"
                    data-testid="first-name-input"
                    (keydown.enter)="submit()"
                  />
                  <mat-error [alcError]="form.controls.firstName" />
                </mat-form-field>

                <mat-form-field class="form-input-stretch">
                  <mat-label class="!text-xl">Last Name</mat-label>
                  <input
                    class="!text-xl"
                    matInput
                    formControlName="lastName"
                    data-testid="last-name-input"
                    (keydown.enter)="submit()"
                  />
                  <mat-error [alcError]="form.controls.lastName" />
                </mat-form-field>
              </div>

              <alc-password-input
                class="form-input-stretch"
                inputClass="!text-xl"
                (keydownEnter)="submit()"
              />

              <alc-password-input
                controlName="confirmPassword"
                label="Confirm Password"
                class="form-input-stretch mb-3"
                inputClass="!text-xl"
                mustMatchField="password"
                [validateCharacters]="false"
                (keydownEnter)="submit()"
              />

              <ion-button
                class="form-buttons"
                expand="block"
                shape="round"
                data-testid="submit-button"
                (click)="submit()"
              >
                Sign Up
              </ion-button>
            </form>
          }

          @default {
            <div class="flex w-full flex-col items-center">
              <p class="mt-6 text-center">
                The link you followed to get here appears to be invalid. Contact
                your administrator for assistance.
              </p>

              <p class="mt-2 text-center">
                <alc-contact-support />
              </p>
            </div>
          }
        }

        <alc-version-marker
          class="w-full items-end pt-4 text-sm text-medium-light"
        />
      </ion-card>
    </div>
  </ion-content>
}
