import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

@Injectable()
export class CustomHammerGestureConfig extends HammerGestureConfig {
  overrides = <Record<string, object>>{
    press: {
      time: 500
    }
  };
}
