<ion-header>
  <ion-toolbar [color]="'primary' | alcTheme">
    <ion-title>Geofence Violation</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="flex min-h-full items-center justify-center bg-surface p-6">
    <div class="flex max-w-lg flex-col items-center justify-center">
      @if (!map) {
        <alc-empty-placeholder>
          <alc-icon-container icon-size="150px">
            <ion-icon src="assets/icon/material/location_off.svg" />
          </alc-icon-container>
        </alc-empty-placeholder>
      }

      <h1 class="text-center text-dark">
        {{ message().primary }}
      </h1>

      <div
        #mapContainer
        class="my-6 min-h-80 w-full min-w-80"
        [ngClass]="{ hidden: !map }"
      ></div>

      <p class="text-center text-lg text-medium-dark">
        {{ message().secondary }}
      </p>

      <div class="mt-6 flex justify-center gap-4">
        <ion-button
          shape="round"
          [color]="'primary' | alcTheme"
          (click)="retry()"
        >
          Try Again
        </ion-button>

        @if (platform !== 'web') {
          @switch (state()) {
            @case ('location-disabled') {
              <ion-button
                shape="round"
                [color]="'tertiary'"
                (click)="openLocationSettings()"
              >
                Location Settings
              </ion-button>
            }

            @case ('location-denied') {
              <ion-button
                shape="round"
                [color]="'tertiary'"
                (click)="openAppSettings()"
              >
                App Settings
              </ion-button>
            }
          }
        }

        <ion-button
          shape="round"
          [color]="'accent' | alcTheme"
          (click)="logout()"
        >
          Logout
        </ion-button>
      </div>

      @if (state() === 'outside-geofence') {
        <p class="my-6 text-xl sm:my-12">OR</p>

        <p class="mt-0 text-lg text-medium-dark">
          Choose one of your facilities below
        </p>

        @for (facility of facilities(); track facility.id) {
          <ion-item
            button
            lines="full"
            class="w-full"
            (click)="changeFacility(facility.id)"
          >
            <ion-icon slot="start" src="assets/icon/material/home.svg" />
            <ion-label>
              <h2>
                {{ facility.displayName || facility.name }}
              </h2>
              <p>
                {{ facility | address }}
              </p>
            </ion-label>
          </ion-item>
        }
      }
    </div>
  </div>
</ion-content>
